import React from 'react';
import Office from './views/Office.js'
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import './App.css';

function App() {
  return (
    <div>
      <ToastContainer
        position="bottom-left" />
      <Office />
    </div>
  );
}

export default App;
