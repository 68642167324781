import React from "react"
import { Col, Row } from 'reactstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import { ReactComponent as DeleteSvg } from "./../assets/images/delete.svg"

class Keyboard extends React.Component {
  state = {
  }

  componentDidMount() {
  }

  render() {
    const { fgColor } = this.props
    return (
      <>
        <Row className="justify-content-center nums-row">
          {this.props.keys.map((key, i) => (
            <Col key={i} className="col-4 col-numb">
              <div
                className={`key d-flex align-items-center justify-content-center ${this.props.animation}`}
                onClick={() => this.props.handleClick(key)}
                style={{ borderColor: fgColor }}>
                <p
                  className={`key-text ${this.props.animation}-text`}
                  style={{ color: fgColor }}>
                  {key === '⇦' ? <DeleteSvg className="delete-icon" /> : key}
                </p>
              </div>
            </Col>
          ))}
        </Row>
      </>
    )
  }
}
export default Keyboard
